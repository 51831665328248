import React, { useEffect, useState } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import SkeletonLoader from '../Skeleton/SkeletonLoader';
import Pagination from '../Pagination/Pagination';
import SearchBar from "../SearchBar/Search";
import "../Card.css";

const GenrePage = () => {
  const { genre } = useParams();
  const navigate = useNavigate();
  const [movies, setMovies] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const moviesPerPage = 20;

  useEffect(() => {
    const fetchMoviesByGenre = async () => {
      try {
        const response = await fetch('https://jkhub-m8d2.onrender.com/api/movies');
        const responseData = await response.json();
        if (Array.isArray(responseData.Movies)) {
          const filteredMovies = responseData.Movies.filter((movie) =>
            movie.genres.some((g) => g.toLowerCase().includes(genre.toLowerCase()))
          );
          // Sort movies by date in descending order
          const sortedMovies = filteredMovies.sort(
            (a, b) => new Date(b.date) - new Date(a.date)
          );
          setMovies(sortedMovies);
        } else {
          console.error('Invalid data format: Movies array not found');
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchMoviesByGenre();
  }, [genre]);

  useEffect(() => {
    const fetchMoviesByGenreAndSearch = async () => {
      try {
        const response = await fetch('https://jkhub-m8d2.onrender.com/api/movies');
        const responseData = await response.json();
        if (Array.isArray(responseData.Movies)) {
          const filteredMovies = responseData.Movies.filter((movie) =>
            movie.genres.some((g) => g.toLowerCase().includes(genre.toLowerCase())) &&
            movie.title.toLowerCase().includes(searchTerm.toLowerCase())
          );
          // Sort movies by date in descending order
          const sortedMovies = filteredMovies.sort(
            (a, b) => new Date(b.date) - new Date(a.date)
          );
          setMovies(sortedMovies);
        } else {
          console.error('Invalid data format: Movies array not found');
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchMoviesByGenreAndSearch();
  }, [genre, searchTerm]);

  const totalMovies = movies.length;
  const totalPages = Math.ceil(totalMovies / moviesPerPage);
  const indexOfLastMovie = currentPage * moviesPerPage;
  const indexOfFirstMovie = indexOfLastMovie - moviesPerPage;
  const currentMovies = movies.slice(indexOfFirstMovie, indexOfLastMovie);

  const handlePageChange = (page) => {
    setCurrentPage(page);
    navigate(`/category/${genre}/page/${page}`);
    window.scrollTo(0, 0);
  };

  const handleSearchChange = (value) => {
    setSearchTerm(value);
    setCurrentPage(1); // Reset to the first page when search term changes
  };

  return (
    <div className="genre-page">
      <h1>{currentPage === 1 ? `${genre.charAt(0).toUpperCase() + genre.slice(1)} Movies` : `Page: ${currentPage}`}</h1>
      <SearchBar onSearch={handleSearchChange} />
      <div className="main1">
        {isLoading ? (
          <SkeletonLoader />
        ) : movies.length > 0 ? (
          currentMovies.map((movie) => (
            <Link key={movie.title} to={`/movies/${movie.title}`}>
              <div className="main" data-aos="fade-in" data-aos-duration="500">
                <div className="box">
                  <img src={movie.image} alt={movie.title} />
                  <div className="jk">
                    <p>{movie.title}</p>
                  </div>
                </div>
              </div>
            </Link>
          ))
        ) : (
          <p>No movies found.</p>
        )}
      </div>
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        onPageChange={handlePageChange}
      />
    </div>
  );
};

export default GenrePage;
