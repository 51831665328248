import React, { useEffect, useState } from "react";
import "./Card.css";
import AOS from "aos";
import "aos/dist/aos.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import Pagination from "./Pagination/Pagination";
import SkeletonLoader from "./Skeleton/SkeletonLoader";
import SearchBar from "./SearchBar/Search";
import Button from "./Button/Button";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { fetchMovies } from "./redux/moviesSlice"; // Import your fetchMovies action

const Home = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const moviesPerPage = 20;

  const navigate = useNavigate();
  const { pageNumber } = useParams();
  const currentPage = parseInt(pageNumber, 10) || 1;

  const dispatch = useDispatch();
  const { items: movies, status } = useSelector((state) => state.movies); // Use Redux state for movies and status

  useEffect(() => {
    if (status === null) {
      dispatch(fetchMovies());
    }
    AOS.init();
  }, [status, dispatch]);

  const filteredMovies = movies.filter((movie) =>
    movie.title.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const totalMovies = filteredMovies.length;
  const totalPages = Math.ceil(totalMovies / moviesPerPage);
  const indexOfLastMovie = currentPage * moviesPerPage;
  const indexOfFirstMovie = indexOfLastMovie - moviesPerPage;
  const currentMovies = filteredMovies.slice(
    indexOfFirstMovie,
    indexOfFirstMovie + moviesPerPage
  );

  const handlePageChange = (page) => {
    navigate(`/page/${page}`);
    window.scrollTo(0, 0);
  };

  const handleSearchChange = (value) => {
    setSearchTerm(value);
    handlePageChange(1);
  };

  return (
    <div className="bg">
      <Button />
      <h1>
        {currentPage === 1
          ? "Latest Releases 🔥"
          : `Page: ${currentPage}`}
      </h1>
      <Helmet>
        <title>
          JKHub Movies | Free Download Movies & Web Series Hindi Dubbed
        </title>
        <meta
          name="description"
          content="JKHub Movies | Download Latest Bollywood And Hollywood Movies, Hindi South Dubbed, Dual Audio Movies and Web Series, Netflix and Amazon Prime Series"
        />
      </Helmet>
      <SearchBar onSearch={handleSearchChange} />
      <div className="main1">
        {status === "loading" ? (
          <SkeletonLoader />
        ) : totalMovies > 0 ? (
          currentMovies.map((movie) => (
            <Link key={movie.id} to={`/movies/${movie.title}`}>
              <div className="main" data-aos="fade-in" data-aos-duration="500">
                <div className="box">
                  <img src={movie.image} alt={movie.title} />
                  <div className="jk">
                    <p>{movie.title}</p>
                  </div>
                </div>
              </div>
            </Link>
          ))
        ) : (
          <p>No movies found.</p>
        )}
      </div>
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        onPageChange={handlePageChange}
      />
    </div>
  );
};

export default Home;
