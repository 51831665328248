import React from 'react'
import "./Footer.css";

const Footer = () => {
  return (
   <footer>
   <p>Copyright © 2024 · Created by JKHub</p>
 </footer>
  )
}

export default Footer;
