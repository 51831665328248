/* eslint-disable jsx-a11y/img-redundant-alt */
import React from "react";
import { FaInstagram, FaYoutube } from "react-icons/fa";
import "./About.css";
import { Helmet } from "react-helmet";

const About = () => {
  return (
    <div>
      <section className="about-us">
      <Helmet>
      <title>About Us - JKHub</title>
        <meta
          name="description"
          content="Download Latest Bollywood ,South India Hindi Dubbed and Hollywood Movies Free, JKHub, 300MB Movies"
        />
      </Helmet>
        <div className="main-us">
          <img src="favicon.ico" alt="My Photo" />
          <div className="abt-text">
            <h1 className="h1-heading">
              About <span>Us</span>
            </h1>
            <p>
              "JKHub Download: Your go-to for the latest movies and series.
              Explore a vast library, seamless navigation, and join a community
              of fellow enthusiasts. Start your cinematic journey today!" <br /> <br /> <span>
              If there are any website issues or unavailable movies and series, DM us on Instagram. <br />
              ( किसी भी वेबसाइट समस्या या अनुपलब्ध फिल्मों और सीरीज के लिए, हमें इंस्टाग्राम पर DM करें। )
              </span>
            </p>
            <a
              href="https://www.instagram.com/jkhub_site?igsh=MTZ1NGNsenJycjVvdA==" target="_blank"
              className="connectbtn" rel="noreferrer"
            >
              Connect with me!
            </a>

            <div className="connect-section">
              <div className="social-icons">
                <a
                  href="https://www.instagram.com/jkhub_site?igsh=MTZ1NGNsenJycjVvdA=="
                  target="_blank"
                  rel="noreferrer"
                >
                  <FaInstagram />
                </a>
                <a
                  href="https://youtube.com/@jk_ringtone?si=x1SLCV8VngaYW4n6"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FaYoutube />{" "}
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <center className="contact-us">Contact us</center>
      <center>
        We remove postings as soon as we can, usually within 4 days. Thank you
        for your understanding.
      </center>
      <br />
      <center>Copyrighted Contents???</center>
      <center>
        What to do if I want you to remove certain copyrighted comments from
        your website? <br /> Please note that we do not host any copyrighted
        content on this website. The comments (text) contains only information
        shared by users that do
        <br /> not contain data that might be copyrighted in any way.However, we
        offer a service to remove comments from our website if
        <br />
        the copyright holder of the content requests so. These removal requests
        are only valid if:
      </center>
      <ul class="b">
        <li>
          You are, or your company is, the copyright holder of the content in
          question.
        </li>
        <li>You provide the exact URLs to the comment.</li>
        <li>You provide the complete name of the content in question.</li>
        <li>
          You send the removal request using a verifiable email address (e.g.
          address@yourname/yourcompany.com).
        </li>
      </ul>
      <center>
        If your request complies with all of these rules, send a mail with
        Contact us page. Please keep the correspondence polite.
      </center>
      <center>
        We remove postings as soon as we can, usually within 4 days. Keep in
        mind that we can only handle removal requests that comply with
        the above rules.
      </center>
    </div>
  );
};

export default About;
