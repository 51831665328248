import React, { useEffect, useState } from "react";
import { useLocation, Link, useNavigate } from "react-router-dom";
import SkeletonLoader from "../Skeleton/SkeletonLoader";
import Pagination from "../Pagination/Pagination";
import "../Card.css";

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const SearchResults = () => {
  const [movies, setMovies] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const query = useQuery();
  const searchTerm = query.get("query");
  const navigate = useNavigate();
  const currentPage = parseInt(query.get("page"), 10) || 1;
  const moviesPerPage = 16;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `https://jkhub-m8d2.onrender.com/api/movies?search=${searchTerm}`
        );
        const responseData = await response.json();

        if (Array.isArray(responseData.Movies)) {
          const simplifiedMovies = responseData.Movies.map((movie) => ({
            id: movie._id,
            title: movie.title,
            movieName: movie.movieName,
            image: movie.image,
            genres: movie.genres.join(", "),
            stars: movie.stars.join(", "),
            language: movie.language,
            type: movie.type,
            date: movie.date,
          }));
          // Sort movies by date in descending order
          const sortedMovies = simplifiedMovies.sort(
            (a, b) => new Date(b.date) - new Date(a.date)
          );
          setMovies(sortedMovies);
        } else {
          console.error("Invalid data format: Movies array not found");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [searchTerm]);

  const totalMovies = movies.length;
  const totalPages = Math.ceil(totalMovies / moviesPerPage);
  const indexOfLastMovie = currentPage * moviesPerPage;
  const indexOfFirstMovie = indexOfLastMovie - moviesPerPage;
  const currentMovies = movies.slice(indexOfFirstMovie, indexOfLastMovie);

  const handlePageChange = (page) => {
    navigate(`/search?query=${searchTerm}&page=${page}`);
    window.scrollTo(0, 0);
  };

  return (
    <div className="bg">
      <h1>
        Search Results for "{searchTerm}"
        {currentPage > 1 ? ` - Page: ${currentPage}` : ""}
      </h1>
      <div className="main11">
        <div className="main1">
          {isLoading ? (
            <SkeletonLoader />
          ) : movies.length > 0 ? (
            currentMovies.map((movie) => (
              <Link key={movie.title} to={`/movies/${movie.title}`}>
                <div className="main" key={movie.id}>
                  <div className="box">
                    <img src={movie.image} alt={movie.title} />
                    <div className="jk">
                      <p>{movie.title}</p>
                    </div>
                  </div>
                </div>
              </Link>
            ))
          ) : (
            <p>No movies found.</p>
          )}
        </div>
      </div>
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        onPageChange={handlePageChange}
      />
    </div>
  );
};

export default SearchResults;
