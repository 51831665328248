import React, { useEffect, useState } from "react";
import { useParams} from "react-router-dom";
import Button from "../Button/Button";
import "./Movieinfo.css";
import { Helmet } from "react-helmet";

const MovieInfo = () => {
  const { title } = useParams();
  const [movie, setMovie] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `https://jkhub-m8d2.onrender.com/api/movies/${title}`
        );

        const movieData = await response.json();
        setMovie(movieData);
        scrollToTop(); // Scroll to the top of the page when data is loaded
      } catch (error) {
        console.error("Error fetching movie data:", error);
      } finally {
        setIsLoading(false); // Set loading state to false after data is fetched
      }
    };

    fetchData();
  }, [title]);

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  if (isLoading) {
    return <div className="ruko">Loading...</div>;
  }

  const images = movie.screenshots.map((screenshot, index) => (
    <img key={index} src={screenshot} alt={`Screenshot ${index + 1}`} />
  ));

  const isWebSeries = movie.type === "series";

  return (
    <div className="display-flex">
      <Helmet>
        <title>{movie.movieName}</title>
        <meta name="description" content={movie.title} />
      </Helmet>
      <div className="main-card">
        <h1 className="heading-h">{movie.title}</h1>
        <img className="card-image" src={movie.image} alt={movie.title} />
        <hr className="main-hr" />
        <h2 className="movie-h2">{movie.movieName}</h2>
        <p className="rating">
          <b>iMDB Rating:</b> <span> {movie.rating}/10 </span>
        </p>
        <p className="genres">
          <b>Genres:</b> {movie.genres.join(", ")}
        </p>

        {movie.stars && movie.stars.length > 0 && (
          <p className="star">
            <b>Stars:</b> {movie.stars.join(", ")}
          </p>
        )}

        {movie.director && (
          <p className="creator">
            <b>Creator:</b> {movie.director}
          </p>
        )}
        <p className="lan">
          <b>Language:</b> {movie.language}
        </p>
        <p className="quality">
          <b>Quality:</b> {movie.quality}
        </p>
        <hr className="main-hr" />

        <p className="screen">Screen-Shots</p>
        <div className="grid-container">
          <div className="screenshots">{images}</div>
        </div>

        <hr className="main-hr" />
        <p className="link">DOWNLOAD LINKS</p>
        <hr className="notmain-hr" />
        <div className="links">
          {movie.download.map((item, index) => (
            <React.Fragment key={index}>
              <p>
                <a href={item[1]} target="_blank" rel="noreferrer">
                  {item[0]}
                </a>
              </p>
              {index !== movie.download.length - 1 && (
                <hr className="notmain-hr" />
              )}
            </React.Fragment>
          ))}
        </div>
        <hr className="main-hr" />
        {isWebSeries && (
          <React.Fragment>
            <p className="single-link">Single Episode Links</p>
            <hr className="notmain-hr" />
            <div>
              {movie &&
                movie.singlelink &&
                movie.singlelink.map((link, index) => (
                  <p key={index} className="sing">
                    EP.{index + 1} -{" "}
                    <a href={link} target="_blank" rel="noreferrer">
                      Download
                    </a>
                  </p>
                ))}
            </div>
            <hr className="main-hr" />
          </React.Fragment>
        )}
        {movie.trailer && (
          <iframe
            width="560"
            height="315"
            src={movie.trailer}
            title="YouTube video player"
            frameBorder="0"
            allowFullScreen
            referrerPolicy="strict-origin-when-cross-origin"
          ></iframe>
        )}

        <p className="descr">
          Here you can download 1080p x264 UHD, 1080p 60FPS, 1080p x265 10Bit,
          4k HDR, 4k 2160p SDR & 3D Movies through Google Drive Links.
          High-quality movies with the best quality options and maximum
          bitrates. We also focus on providing the best quality audio available.
          4k HEVC Dolby Atmos is one of the best High-quality formats with low
          file sizes. We provide a fast & safe direct google drive link to
          download the best quality stuff from the best Encoders. You can easily
          clone our files into your G-Drive and make your own collection of
          high-quality movies. Google Drive Direct/Login to download/Make Clone
          option are the best way to download or make a copy in your google
          drive. <br /> <br /> <span> Note:</span> We Do not host any files on
          our server. All files shared here are collected from the internet from
          various Encoders and hosted on third-party sites. We do not accept
          responsibility for content hosted on third-party websites. We just
          index those links which are already available on the internet.
        </p>
      </div>
      <div className="tag-button">
        <Button />
      </div>
    </div>
  );
};

export default MovieInfo;
