import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import "./Card.css";
import AOS from "aos";
import "aos/dist/aos.css";
import Pagination from "./Pagination/Pagination";
import SkeletonLoader from "./Skeleton/SkeletonLoader";
import SearchBar from "./SearchBar/Search";
import Button from "./Button/Button";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from 'react-redux';
import { fetchMovies } from './redux/moviesSlice'; // Import your fetchMovies action

const Bollywood = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const moviesPerPage = 20;

  const navigate = useNavigate();
  const { pageNumber } = useParams();
  const currentPage = parseInt(pageNumber, 10) || 1;

  const dispatch = useDispatch();
  const { items: movies, status } = useSelector((state) => state.movies); // Use Redux state for movies and status

  useEffect(() => {
    if (status === null) {
      dispatch(fetchMovies());
    }
    AOS.init();
  }, [status, dispatch]);

  const bollywoodMovies = movies.filter(
    (movie) => movie.type.includes("bollywood") && movie.title && movie.image
  );

  const filteredMovies = bollywoodMovies.filter((movie) =>
    movie.title.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const totalMovies = filteredMovies.length;
  const totalPages = Math.ceil(totalMovies / moviesPerPage);
  const indexOfLastMovie = currentPage * moviesPerPage;
  const indexOfFirstMovie = indexOfLastMovie - moviesPerPage;
  const currentMovies = filteredMovies.slice(
    indexOfFirstMovie,
    indexOfLastMovie
  );

  const handlePageChange = (page) => {
    navigate(`/bollywood/page/${page}`);
    window.scrollTo(0, 0);
  };

  const handleSearchChange = (value) => {
    setSearchTerm(value);
    handlePageChange(1); // Reset to the first page when search term changes
  };

  return (
    <div className="bg">
      <Button />
      <h1>{currentPage === 1 ? "Bollywood Movies 🎬" : `Page: ${currentPage}`}</h1>
      <Helmet>
        <title>
          {currentPage === 1
            ? "JKHub Movies - Bollywood Hindi Movies Free Download"
            : `Page ${currentPage} | Bollywood | JKHub Movies`}
        </title>
        <meta
          name="description"
          content="JKHub Movies, Bollywood Full Movies free Download High Quality Hd, Bollywood Full Movies full BluRay, Bollywood Full Movies 4K Mobile movies, JKHub Bollywood Full Movie"
        />
      </Helmet>
      <SearchBar onSearch={handleSearchChange} />
      <div className="main1">
        {status === 'loading' ? (
          <SkeletonLoader />
        ) : totalMovies > 0 ? (
          currentMovies.map((movie) => (
            <Link
              to={`/movies/${movie.title}`}
              key={movie.id}
              className="main"
              data-aos="fade-in"
              data-aos-duration="500"
            >
              <div className="box">
                <img src={movie.image} alt={movie.title} />
                <div className="jk">
                  <p>{movie.title}</p>
                </div>
              </div>
            </Link>
          ))
        ) : (
          <p>No movies found.</p>
        )}
      </div>
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        onPageChange={handlePageChange}
      />
    </div>
  );
};

export default Bollywood;
