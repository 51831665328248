import React, { useState } from "react";
import { Routes, Route } from "react-router-dom";
import { Provider } from "react-redux";
import { store } from "./Components/store";
import Navbar from "./Components/Navbar/Navbar";
import Home from "./Components/Home";
import Bollywood from "./Components/Bollywood";
import Hollywood from "./Components/Hollywood";
import Drama from "./Components/K-Drama";
import Series from "./Components/Web-Series";
import About from "./Components/About/About";
import Movieinfo from "./Components/Moviesdata/Movieinfo";
import Footer from "./Components/Footer/Footer";
import { Helmet } from "react-helmet";
import SearchResults from "./Components/SearchBar/SearchResults";
import GenrePage from "./Components/Button/Buttoninfo";
import Desclaimer from "./Components/DMCA/Desclaimer";

const App = () => {
  const [searchTerm, setSearchTerm] = useState(""); // Initialize searchTerm state

  return (
    <Provider store={store}>
      <>
        <Navbar />
        <Helmet>
          <title>
            JKHub Movies | Latest Free Download Movies & Web Series Hindi Dubbed
          </title>
          <meta
            name="description"
            content="JKHub Movies, Download Latest Bollywood And Hollywood Movies, Hindi South Dubbed, Dual Audio Movies and Web Series, Netflix and Amazon Prime Series"
          />
          <meta
            name="keywords"
            content="JKHub, JKHub Movies, jkhub online, Movies download website, jkhub.online, JK, jkhub movies, jkhub movie, jkhub.site, jkhub online movie download, jkhub movies download"
          />
        </Helmet>
        <Routes>
          <Route
            path="/"
            element={
              <Home searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
            }
          />
          <Route
            path="/page/:pageNumber"
            element={
              <Home searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
            }
          />
          <Route
            path="/Bollywood"
            element={
              <Bollywood
                searchTerm={searchTerm}
                setSearchTerm={setSearchTerm}
              />
            }
          />
          <Route
            path="/Bollywood/page/:pageNumber"
            element={
              <Bollywood
                searchTerm={searchTerm}
                setSearchTerm={setSearchTerm}
              />
            }
          />
          <Route
            path="/Hollywood"
            element={
              <Hollywood
                searchTerm={searchTerm}
                setSearchTerm={setSearchTerm}
              />
            }
          />
          <Route
            path="/Hollywood/page/:pageNumber"
            element={
              <Hollywood
                searchTerm={searchTerm}
                setSearchTerm={setSearchTerm}
              />
            }
          />
          <Route
            path="/K-Drama"
            element={
              <Drama searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
            }
          />
          <Route
            path="/K-Drama/page/:pageNumber"
            element={
              <Drama searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
            }
          />
          <Route
            path="/Web-Series"
            element={
              <Series searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
            }
          />
          <Route
            path="/Web-Series/page/:pageNumber"
            element={
              <Series searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
            }
          />
          <Route path="/movies/:title" element={<Movieinfo />} />
          <Route path="/category/:genre" element={<GenrePage />} />
          <Route
            path="/category/:genre/page/:pageNumber"
            element={<GenrePage />}
          />
          <Route path="/search" element={<SearchResults />} />
          <Route path="/search/page/:pageNumber" element={<SearchResults />} />
          <Route path="/About" element={<About />} />
          <Route path="/disclaimer" element={<Desclaimer />} />
        </Routes>
        <Footer />
      </>
    </Provider>
  );
};

export default App;
